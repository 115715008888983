import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConsentService {
  private consent$ = new BehaviorSubject<boolean>(true);
  public consentAccepted$: Observable<boolean> = this.consent$.asObservable();

  updateConsent(value: boolean) {
    this.consent$.next(value);
  }
}
