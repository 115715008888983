import { patchState, signalStoreFeature, withHooks, withMethods, withState } from '@ngrx/signals';
import { Article } from '@lobos/common-v3';
import { isPlatformBrowserProvider } from '../../providers/is-platform-browser.provider';
import { inject } from '@angular/core';
import { SuedoArticle } from '../../interfaces/suedo-article.interface';
import { TrackingFactory } from '../../services/tracking/tracking.factory';

export interface CompareState {
  compareItems: SuedoArticle[];
}

const initialCompareState: CompareState = {
  compareItems: [],
};

export function withCompareFeature() {
  return signalStoreFeature(
    withState(initialCompareState),
    withMethods((store, isBrowser = isPlatformBrowserProvider(), tracking = inject(TrackingFactory)) => ({
      addCompareItem: (article: Article) => {
        if (store.compareItems().find((item) => item.sArticleID === article.sArticleID)) {
          return;
        }
        patchState(store, { compareItems: [...store.compareItems(), article] });
        localStorage.setItem('compareItems', JSON.stringify(store.compareItems()));
        tracking.trackEvent({ name: 'add_compare_item', payload: {}});
        // successHandler.displaySuccess({
        //   params: { articleName: article.sName },
        //   label: 'compare.add-to-compare-success',
        //   translate: true,
        //   translateScope: 'catalog',
        // });
      },
      removeCompareItem: (article: Article) => {
        const newItems = store.compareItems().filter((item) => item.sArticleID !== article.sArticleID);
        patchState(store, { compareItems: newItems });
        localStorage.setItem('compareItems', JSON.stringify(newItems));
        tracking.trackEvent({ name: 'remove_compare_item', payload: {}});
        // successHandler.displaySuccess({
        //   params: { articleName: article.sName },
        //   label: 'compare.remove-from-compare-success',
        //   translate: true,
        //   translateScope: 'catalog',
        // });
      },
      removeAllCompareItems: () => {
        patchState(store, { compareItems: [] });
        localStorage.setItem('compareItems', JSON.stringify([]));
        // successHandler.displaySuccess({
        //   label: 'compare.remove-all-from-compare-success',
        //   translate: true,
        //   translateScope: 'catalog',
        // });
      },
      init: () => {
        if (isBrowser) {
          const items = localStorage.getItem('compareItems');
          if (items) {
            patchState(store, { compareItems: JSON.parse(items) });
          }
        }
      },
    })),
    withHooks({
      onInit({ init }) {
        init();
      },
    }),
  );
}
