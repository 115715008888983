import { Injectable } from '@angular/core';
import { ItemInterface } from '../models/ecommerce/item.interface';
import { Article, CartItem, HierarchicalCategory, Hierarchy } from '@lobos/common-v3';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerHelper {
  public static readonly ITEM_MAX_CATEGORIES: number = 5;

  public static hierarchicalCategoriesToItemCategories(hierCat: HierarchicalCategory[] | Hierarchy[] = []): Partial<ItemInterface> {
    const itemPartial: Partial<ItemInterface> = {};
    const hierarchy = (hierCat[0] as any)?.sUrlPath ? (hierCat[0] as any).hierarchy : hierCat;
    return hierarchy
      .map((cat: HierarchicalCategory, index: number): Partial<ItemInterface> => {
        if (index >= GoogleTagManagerHelper.ITEM_MAX_CATEGORIES) {
          return {};
        }

        return { [`item_category${index === 0 ? '' : index + 1}`]: cat.sValue };
      })
      .reduce(
        (obj: Partial<ItemInterface>, item: Partial<ItemInterface>) => ({
          ...obj,
          ...item,
        }),
        itemPartial,
      );
  }

  public static articleToItem(article: Article): ItemInterface {
    return {
      item_id: article.sArticleID.toString(),
      item_name: article.sName,
      currency: article.oPriceInfo?.sCurrencyCode,
      price: GoogleTagManagerHelper.articlePrice(article),
      discount: article.oPriceInfo?.decSpecialPriceNet ? article.oPriceInfo?.decBasePriceNet - article.oPriceInfo?.decSpecialPriceNet : 0,
      ...GoogleTagManagerHelper.hierarchicalCategoriesToItemCategories(
        article.oProductInfo?.length ? article.oProductInfo[0].listHierarchicalCategories : [],
      ),
    };
  }

  public static cartItemToGoogleItem(cartItem: CartItem): ItemInterface {
    return {
      item_id: cartItem.sArticleID.toString(),
      item_name: cartItem.sArticleName,
      currency: 'CHF',
      price: cartItem.decItemNetAmountFC,
      discount: 0,
      ...(cartItem.oArticle
        ? GoogleTagManagerHelper.hierarchicalCategoriesToItemCategories(
            cartItem.oArticle.oProductInfo?.length ? cartItem.oArticle.oProductInfo[0].listHierarchicalCategories : [],
          )
        : {}),
    };
  }

  public static articlePrice(article: Article): number {
    return article.oPriceInfo?.decSpecialPriceNet || article.oPriceInfo?.decBasePriceNet || 0;
  }
}
