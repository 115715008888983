import { Component, Inject, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GlobalStore } from '../../store/global.store';
import { SuedoCreateCartItemInterface } from '../../interfaces/suedo-create-cart-item.interface';
import { TranslocoDirective } from '@jsverse/transloco';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-qty-modal',
  templateUrl: './toxin-modal.component.html',
  styleUrls: ['./toxin-modal.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    NgIf,
  ],
})
export class ToxinModalComponent implements OnInit {
  store = inject(GlobalStore);
  oCartItem!: SuedoCreateCartItemInterface;

  constructor(
    @Inject(MatDialogRef) public dialogRef: MatDialogRef<ToxinModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SuedoCreateCartItemInterface,
  ) {}

  ngOnInit() {
    this.mapDataObject(this.data);
  }

  mapDataObject(data: SuedoCreateCartItemInterface) {
    this.oCartItem = data;
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  openLoginModal(): void {
    this.dialogRef.close('login');
  }
}
