import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, FormsModule],
})
export class InputComponent implements OnInit {
  @Input() public label: string = '';

  @Input() public type: string = 'text';

  @Input() public name: string = '';

  @Input() public placeholder: string = '';

  @Input() public form: UntypedFormGroup | undefined;

  @Input() public required: boolean = false;

  @Input() public hasError: boolean = false;

  @Input() public errorText: string = '';

  @Input() public min?: number | string = undefined;

  @Input() public maxlength: any;

  @Input() public disabled: boolean = false;

  @Input() public enableEditButtonLabel: string = '';

  @Input()
  public value: string = '';

  @Input()
  public valueChangeDebouncing: number = 500;

  @Output()
  public valueChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() public editButtonClick: EventEmitter<void> = new EventEmitter<void>();

  public valueChangeDebouncer: Subject<string> = new Subject<string>();

  ngOnInit() {
    this.valueChangeDebouncer.pipe(debounceTime(this.valueChangeDebouncing)).subscribe((input: string) => this.valueChange.emit(input));
  }
}
