import { inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventTrackingInterface, PageTracking, TrackingAdapterInterface } from '@lobos/common-v3';
import { ErrorHandlerService } from '../error/error-handler.service';
import { ConsentService } from './consent.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class TrackingFactory implements TrackingAdapterInterface {
  private adapters: TrackingAdapterInterface[] = [];
  private eventCache: EventTrackingInterface[] = [];
  private pageCache: PageTracking[] = [];
  private consentAccepted = false;

  errorHandlerService = inject(ErrorHandlerService);
  consentService = inject(ConsentService);

  constructor() {
    this.consentService.consentAccepted$
      .pipe(
        tap((accepted: boolean) => {
          if (accepted) {
            this.consentAccepted = accepted;
            this.pushEventCache();
            this.pushPageCache();
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  public register(adapter: TrackingAdapterInterface): void {
    this.adapters.push(adapter);
  }

  public async trackEvent<T extends EventTrackingInterface>(event: T): Promise<void> {
    if (!this.consentAccepted) {
      this.eventCache.push(event);
      return;
    }

    for (const adapter of this.adapters) {
      try {
        await adapter.trackEvent(event);
      } catch (error: any) {
        this.errorHandlerService.handleError({
          label: 'TrackingFactory.trackEvent()',
          ...error,
          error,
          translate: false,
        });
      }
    }
  }

  public async trackPage<T extends PageTracking>(page: T): Promise<void> {
    if (!this.consentAccepted) {
      this.pageCache.push(page);
      return;
    }

    for (const adapter of this.adapters) {
      try {
        await adapter.trackPage(page);
      } catch (error: any) {
        this.errorHandlerService.handleError({ label: 'TrackingFactory.trackPage()', ...error, error });
      }
    }
  }

  private pushEventCache() {
    this.eventCache.forEach((item) => {
      this.trackEvent(item);
    });
    this.eventCache = [];
  }

  private pushPageCache() {
    this.pageCache.forEach((item) => {
      this.trackPage(item);
    });
    this.pageCache = [];
  }
}
