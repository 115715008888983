<!-- start login modal -->
<ng-container *transloco="let t; scope: 'core'">
  <div class="js-login-modal fixed z-60 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog"
       aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-200 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <form
        (submit)="login()"
        class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
      >
        <div class="block absolute top-0 right-0 pt-4 pr-4 z-10">
          <button
            (click)="dialogRef.close()"
            type="button"
            class="js-close-modal bg-white p-2 inline-flex items-center justify-center hover:text-primary hover:bg-gray-100 focus:text-primary rounded"
          >
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:text-left">
            <h3 class="text-lg leading-6 font-bold" id="modal-title">
              {{ data.forgotPassword ? t("core.login-modal.forgot-password") : t("core.login-modal.title") }}
            </h3>
            <div class="mt-2">
              <p class="text-sm">
                {{ data.forgotPassword ? t("core.login-modal.forgot-password-subtitle") : t("core.login-modal.subtitle") }}
              </p>
              <app-input
                name="username"
                [label]="t('core.login-modal.username')"
                [type]="'text'"
                [form]="form"
                [hasError]="!!form.get('username')?.touched && !!form.get('username')?.errors"
                [errorText]="
                  form
                    | getError
                      : 'username'
                      : {
                          required: t('core.login-modal.error.username-required'),
                          server: t('core.login-modal.error.server')
                        }
                "
              ></app-input>
              <app-input
                *ngIf="data.forgotPassword === false"
                name="password"
                [label]="t('core.login-modal.password')"
                [type]="'password'"
                [form]="form"
                [hasError]="!!form.get('password')?.touched && !!form.get('password')?.errors"
                [errorText]="
                  form
                    | getError
                      : 'password'
                      : {
                          required: t('core.login-modal.error.password-required'),
                          server: t('core.login-modal.error.server')
                        }
                "
              ></app-input>
            </div>
          </div>
        </div>
        <div *ngIf="!data.forgotPassword; else forgotPasswordIsActive"
             class="mt-5 sm:mt-4 flex justify-between items-end">
          <app-text-button [type]="'submit'" [text]="t('core.login-modal.button-title')"></app-text-button>
          <a (click)="toggleForgotPassword()" class="text-black hover:text-primary text-xs w-auto cursor-pointer">{{
              t("core.login-modal.forgot-password")
            }}</a>
        </div>
        <ng-template #forgotPasswordIsActive>
          <div *ngIf="requestSend" class="mt-5 sm:mt-4 flex">
            <p class="text-sm text-state2">
              {{ t("core.login-modal.request-sended-message", { email: this.form.controls["username"].value }) }}
            </p>
          </div>
          <div class="mt-5 sm:mt-4 flex justify-between items-end">
            <app-text-button
              (click)="requestSend ? toggleForgotPassword() : requestPassword()"
              [text]="requestSend ? t('core.login-modal.back-to-login') : t('core.login-modal.button-request-password')"
            ></app-text-button>
            <a *ngIf="!requestSend" (click)="toggleForgotPassword()"
               class="text-black hover:text-primary text-xs w-auto cursor-pointer">{{
                t("core.login-modal.back-to-login")
              }}</a>
          </div>
        </ng-template>
        <div class="mt-6">
          <h3 class="text-lg leading-6 font-bold mb-2">{{ t("core.login-modal.reg-title") }}</h3>
          <p class="text-sm">
            <a class="reglink" (click)="dialogRef.close()" [routerLink]="['/registration' | urlPath]">{{
                t("core.login-modal.here")
              }}</a>
            {{ t("core.login-modal.register-here") }}
          </p>
        </div>
      </form>
    </div>
  </div>
</ng-container>
<!-- end login modal -->
