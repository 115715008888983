import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';

@Pipe({
  name: 'getError',
  pure: false,
  standalone: true,
})
export class GetErrorPipe implements PipeTransform {
  public transform(form: UntypedFormGroup, control: string, errorCodes: { [errorCode: string]: string }): string {
    const errors: ValidationErrors | null | undefined = form.get(control)?.errors;
    if (!errors) {
      return '';
    }

    for (const error of Object.keys(errors)) {
      if (!errorCodes[error]) {
        continue;
      }

      return errorCodes[error];
    }

    return '';
  }
}
