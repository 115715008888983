import { Cart, CartHeader, CartItem } from '@lobos/common-v3';
import { patchState, signalStoreFeature, type, withHooks, withMethods, withState } from '@ngrx/signals';
import { effect, inject, Signal } from '@angular/core';
import { firstValueFrom, Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CreateCartDto, ReorderCartDto } from '@lobos/gateway-v3';
import { FeatureStatus } from '../model/feature-status.enum';
import { ErrorHandlerService } from '../../services/error/error-handler.service';
import { now } from 'lodash';
import { TrackingFactory } from '../../services/tracking/tracking.factory';
import { AddToCartEvent } from '../../services/tracking/adapters/google-tag-manager/models/ecommerce/events/add-to-cart.event';
import { RemoveFromCartEvent } from '../../services/tracking/adapters/google-tag-manager/models/ecommerce/events/remove-from-cart.event';
import { GoogleTagManagerHelper } from '../../services/tracking/adapters/google-tag-manager/services/google-tag-manager.helper';
import { PurchaseEvent } from '../../services/tracking/adapters/google-tag-manager/models/ecommerce/events/purchase.event';
import { SuedoCreateCartItemInterface } from '../../interfaces/suedo-create-cart-item.interface';
import { SuedoUser } from '../../interfaces/suedo-user.interface';
import { ToxinModalComponent } from '../../components/toxin-modal/toxin-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { LoginModalComponent } from '../../components/login-modal/login-modal.component';
import { UpdateCartHeaderInterface } from '../../interfaces/update-cart-header.interface';
import { Router } from '@angular/router';
import { UrlHelperService } from '../../services/url/url-helper.service';
import { SuccessHandlerService } from '../../services/success/success-handler.service';

type CartState = {
  activeCart: Cart | null;
  allCartHeaders: CartHeader[];
  isCartLoading: boolean;
};

const initialCartState: CartState = {
  activeCart: null,
  allCartHeaders: [],
  isCartLoading: false,
};

const cartBaseURL = '/api/so-carts'; // TODO move to config

export function withCartFeature() {
  return signalStoreFeature(
    {
      state: type<{
        authStatus: FeatureStatus;
        user: SuedoUser | null;
      }>(),
      signals: type<{
        isLoggedIn: Signal<boolean>;
      }>(),
    },
    withState(initialCartState),
    withMethods(
      (
        store,
        http = inject(HttpClient),
        errorHandler = inject(ErrorHandlerService),
        successHandler = inject(SuccessHandlerService),
        tracking = inject(TrackingFactory),
        dialog = inject(MatDialog),
        router = inject(Router),
        urlHelper = inject(UrlHelperService),
      ) => ({
        createCart: async (createCart: CreateCartDto) => {
          try {
            if (!createCart.sCartName) createCart.sCartName = new Date(now()).toString();
            patchState(store, { isCartLoading: true });

            const header = await firstValueFrom(http.post<CartHeader>(`${cartBaseURL}`, createCart));
            const newHeaders = [...store.allCartHeaders()];
            newHeaders.push(header);
            patchState(store, { allCartHeaders: newHeaders });

            const newActiveCart = await firstValueFrom(activateCartById(header.lngOrderID, http));
            patchState(store, { activeCart: newActiveCart });
          } catch (e: any) {
            errorHandler.handleError({
              label: 'errors.create-cart-error',
              translate: true,
              translateScope: 'cart',
              display: true,
              ...e,
            });
          } finally {
            patchState(store, { isCartLoading: false });
          }
        },
        createCartItem: async (createCartItemDto: SuedoCreateCartItemInterface) => {
          try {
            patchState(store, { isCartLoading: true });

            // Custom function to handle Toxins
            if (isToxin(createCartItemDto, store.isLoggedIn(), !!store.user()?.oCustomer?.bPrivateCustomer)) {
              await handleIsToxin(createCartItemDto, dialog);
              return;
            }

            const cart = await firstValueFrom(
              http.post<Cart>(`${cartBaseURL}/${store.activeCart()?.oSalesMaster.lngOrderID || '0'}/items`, createCartItemDto),
            );
            if (!store.activeCart() && store.isLoggedIn()) {
              const newAllCartHeaders = [...store.allCartHeaders()];
              newAllCartHeaders.push(cart.oSalesMaster);
              patchState(store, { allCartHeaders: newAllCartHeaders });
            }
            patchState(store, { activeCart: cart });
            updateCartHeaders(cart, store);
            successHandler.displaySuccess({
              params: { articleName: cart.oSalesItemList.slice(-1)[0].sArticleName },
              label: 'success.add-cart-item',
              translate: true,
              translateScope: 'cart',
            });
            await tracking.trackEvent<AddToCartEvent>({
              name: 'add_to_cart',
              payload: {
                ecommerce: {
                  currency: '',
                  value: cart.oSalesItemList.slice(-1)[0].decItemNetAmountFC,
                  items: [{ ...GoogleTagManagerHelper.cartItemToGoogleItem(cart.oSalesItemList.slice(-1)[0]) }],
                },
              },
            });
          } catch (e: any) {
            errorHandler.handleError({
              label: 'errors.create-cart-item-error',
              translate: true,
              translateScope: 'cart',
              display: true,
              ...e,
            });
          } finally {
            patchState(store, { isCartLoading: false });
          }
        },
        createCartItems: async (createCartItemDto: SuedoCreateCartItemInterface[]) => {
          try {
            patchState(store, { isCartLoading: true });

            const cart = await firstValueFrom(
              http.post<Cart>(`${cartBaseURL}/${store.activeCart()?.oSalesMaster.lngOrderID || '0'}/items/bulk`, createCartItemDto),
            );
            if (!store.activeCart()) {
              const newAllCartHeaders = [...store.allCartHeaders()];
              newAllCartHeaders.push(cart.oSalesMaster);
              patchState(store, { allCartHeaders: newAllCartHeaders });
            }
            patchState(store, { activeCart: cart });

            updateCartHeaders(cart, store);
          } catch (e: any) {
            errorHandler.handleError({
              label: 'errors.create-cart-item-error',
              translate: true,
              translateScope: 'cart',
              display: true,
              ...e,
            });
          } finally {
            patchState(store, { isCartLoading: false });
          }
        },
        updateCart: async (updateCartDto: UpdateCartHeaderInterface, cartId: string | number) => {
          try {
            patchState(store, { isCartLoading: true });

            const cart = await firstValueFrom(http.put<Cart>(`${cartBaseURL}/${cartId}`, updateCartDto));
            if (store.activeCart()?.oSalesMaster.lngOrderID == cartId) {
              patchState(store, { activeCart: cart });
            }

            updateCartHeaders(cart, store);
          } catch (e: any) {
            errorHandler.handleError({
              label: 'errors.update-cart-error',
              translate: true,
              translateScope: 'cart',
              display: true,
              ...e,
            });
          } finally {
            patchState(store, { isCartLoading: false });
          }
        },
        updateCartItem: async (cartId: string | number, itemId: string | number, updateCartItemDto: CartItem) => {
          try {
            patchState(store, { isCartLoading: true });

            const cart = await firstValueFrom(http.put<Cart>(`${cartBaseURL}/${cartId}/items/${itemId}`, updateCartItemDto));
            patchState(store, { activeCart: cart });

            updateCartHeaders(cart, store);
          } catch (e: any) {
            errorHandler.handleError({
              label: 'errors.update-cart-item-error',
              translate: true,
              translateScope: 'cart',
              display: true,
              ...e,
            });
          } finally {
            patchState(store, { isCartLoading: false });
          }
        },
        removeCart: async (cartId: string | number) => {
          try {
            patchState(store, { isCartLoading: true });

            await firstValueFrom(http.delete(`${cartBaseURL}/${cartId}`));
            patchState(store, { allCartHeaders: [...store.allCartHeaders()].filter((header) => header.lngOrderID !== cartId) }); // remove header of deleted cart from state

            const cart = await firstValueFrom(getNewActiveCartAfterSubmitOrDelete([...store.allCartHeaders()], http));
            patchState(store, { activeCart: cart });
          } catch (e: any) {
            errorHandler.handleError({
              label: 'errors.remove-cart-error',
              translate: true,
              translateScope: 'cart',
              display: true,
              ...e,
            });
          } finally {
            patchState(store, { isCartLoading: false });
          }
        },
        removeCartItem: async (cartId: string | number, itemId: string | number) => {
          try {
            patchState(store, { isCartLoading: true });

            const deleteItem = store.activeCart()?.oSalesItemList.find((item) => item.shtFixedItemID == itemId);
            const cart = await firstValueFrom(http.delete<Cart>(`${cartBaseURL}/${cartId}/items/${itemId}`));
            patchState(store, { activeCart: cart });

            updateCartHeaders(cart, store);
            successHandler.displaySuccess({
              params: { articleName: deleteItem?.sArticleName || '' },
              label: 'success.remove-cart-item',
              translate: true,
              translateScope: 'cart',
            });
            if (deleteItem) {
              await tracking.trackEvent<RemoveFromCartEvent>({
                name: 'remove_from_cart',
                payload: {
                  ecommerce: {
                    currency: '',
                    value: deleteItem.decItemNetAmountFC,
                    items: [{ ...GoogleTagManagerHelper.cartItemToGoogleItem(deleteItem) }],
                  },
                },
              });
            }
          } catch (e: any) {
            errorHandler.handleError({
              label: 'errors.remove-cart-item-error',
              translate: true,
              translateScope: 'cart',
              display: true,
              ...e,
            });
          } finally {
            patchState(store, { isCartLoading: false });
          }
        },
        submitCart: async (cartId: string | number, createOffer: boolean) => {
          try {
            patchState(store, { isCartLoading: true });

            let params = new HttpParams();
            if (createOffer) params = params.set('submit-mode', 'offer');
            const order = await firstValueFrom(http.put<Cart>(`${cartBaseURL}/${cartId}/submit`, {}, { params }));

            patchState(store, { allCartHeaders: [...store.allCartHeaders()].filter((header) => header.lngOrderID !== cartId) }); // remove header of submitted cart from state

            const newCart = await firstValueFrom(getNewActiveCartAfterSubmitOrDelete([...store.allCartHeaders()], http));
            patchState(store, { activeCart: newCart });

            if (createOffer) {
              await router.navigate([urlHelper.getUrlPath('checkout/success-offer'), cartId]);
            } else {
              await router.navigate([urlHelper.getUrlPath('checkout/success'), cartId]);
            }

            await tracking.trackEvent<PurchaseEvent>({
              name: 'purchase',
              payload: {
                ecommerce: {
                  transaction_id: order.oSalesMaster.lngOrderID.toString(),
                  currency: 'CHF',
                  value: order.oSalesItemList.reduce((last: number, current: CartItem) => last + current.decItemNetAmountFC, 0),
                  items: order.oSalesItemList.map((item: CartItem) => ({
                    item_id: item.sArticleID.toString(),
                    item_name: item.sArticleName,
                    price: item.decItemNetAmountFC,
                    ...GoogleTagManagerHelper.hierarchicalCategoriesToItemCategories(
                      item.oArticle.oProductInfo?.length ? item.oArticle.oProductInfo[0].listHierarchicalCategories : [],
                    ),
                    quantity: item.decQuantityOrdered,
                  })),
                },
              },
            });
          } catch (e: any) {
            errorHandler.handleError({
              label: 'errors.submit-cart-error',
              translate: true,
              translateScope: 'cart',
              display: true,
              ...e,
            });
          } finally {
            patchState(store, { isCartLoading: false });
          }
        },
        changeActiveCart: async (cartId: string | number) => {
          try {
            patchState(store, { isCartLoading: true });

            const cart = await firstValueFrom(http.put<Cart>(`${cartBaseURL}/${cartId}/activate`, {}));
            patchState(store, { activeCart: cart });
          } catch (e: any) {
            errorHandler.handleError({
              label: 'errors.change-active-cart-error',
              translate: true,
              translateScope: 'cart',
              display: true,
              ...e,
            });
          } finally {
            patchState(store, { isCartLoading: false });
          }
        },
        // attention! this orders the cart immediately and does not put items into the cart
        reorderCart: async (reorderCart: ReorderCartDto) => {
          try {
            patchState(store, { isCartLoading: true });

            await firstValueFrom(http.post<Cart>(`${cartBaseURL}/reorder`, reorderCart));
            successHandler.displaySuccess({
              label: 'orders.order-detail.reorder-success',
              translate: true,
              translateScope: 'profile',
            });
          } catch (e: any) {
            errorHandler.handleError({
              label: 'errors.reorder-cart-error',
              translate: true,
              translateScope: 'cart',
              display: true,
              ...e,
            });
          } finally {
            patchState(store, { isCartLoading: false });
          }
        },
        initCartFeature: async (isLoggedIn: boolean, authStatus: FeatureStatus) => {
          try {
            if (authStatus === FeatureStatus.initialized) {
              patchState(store, { isCartLoading: true });

              // when not logged in can not have multiple carts
              patchState(store, { allCartHeaders: [] });

              const cart = await firstValueFrom(http.get<Cart>(`${cartBaseURL}/active`));
              patchState(store, { activeCart: cart });
              if (isLoggedIn) {
                let cartHeaders = await firstValueFrom(http.get<CartHeader[]>(cartBaseURL));
                if (!cartHeaders.find((header) => header.lngOrderID == cart.oSalesMaster.lngOrderID)) {
                  const newAllCartHeaders = [...store.allCartHeaders()];
                  newAllCartHeaders.push(cart.oSalesMaster);
                  cartHeaders = newAllCartHeaders;
                }
                patchState(store, { allCartHeaders: cartHeaders });
              }
            }
          } catch (e: any) {
            if (e.status === 404) {
              patchState(store, { activeCart: null });
              errorHandler.handleError({
                label: e.error.message,
                display: false,
                ignore: true,
                ...e,
              });
            } else {
              errorHandler.handleError({
                label: 'Error when initializing cart state feature.',
                display: false,
                ...e,
              });
            }
          } finally {
            patchState(store, { isCartLoading: false });
          }
        },
      }),
    ),
    withHooks({
      onInit({ authStatus, isLoggedIn, initCartFeature }) {
        effect(
          () => {
            initCartFeature(isLoggedIn(), authStatus());
          },
          { allowSignalWrites: true },
        );
      },
    }),
  );
}

function getNewActiveCartAfterSubmitOrDelete(allCartHeaders: CartHeader[], http: HttpClient): Observable<Cart | null> {
  return allCartHeaders.length
    ? activateCartById(allCartHeaders.sort((a, b) => b.dtEntryDate - a.dtEntryDate)[0].lngOrderID, http)
    : of(null);
}

function activateCartById(id: string | number, http: HttpClient) {
  return http.put<Cart>(`${cartBaseURL}/${id}/activate`, {});
}

function updateCartHeaders(cart: Cart, store: any) {
  // update specific cart header in array of all cart headers
  const updatedCartHeaders = [...store.allCartHeaders()];
  const index = updatedCartHeaders.findIndex((header) => header.lngOrderID === cart.oSalesMaster.lngOrderID);
  if (index !== -1) {
    updatedCartHeaders[index] = cart.oSalesMaster;
    patchState(store, { allCartHeaders: updatedCartHeaders });
  } else if (!store.isLoggedIn()) {
    return;
  } else {
    throw new Error("Couldn't find index in array of cart headers");
  }
}

function isToxin(cartItem: SuedoCreateCartItemInterface, isLoggedIn: boolean, isPrivateCustomer: boolean): boolean {
  if (cartItem.oArticle?.shtPL1WebNotAvailable) {
    return !isLoggedIn || isPrivateCustomer;
  } else {
    return false;
  }
}

async function handleIsToxin(cartItem: SuedoCreateCartItemInterface, dialog: MatDialog): Promise<void> {
  const toxinDialogRef = await firstValueFrom(
    dialog
      .open(ToxinModalComponent, {
        width: '400px',
        data: cartItem,
      })
      .afterClosed(),
  );

  if (toxinDialogRef === 'login') {
    dialog.open(LoginModalComponent, { data: { forgotPassword: false } });
  }
}
