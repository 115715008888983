import { patchState, signalStoreFeature, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { AuthResponseInterface, HasCookie, ResetPasswordInterface } from '@lobos/common-v3';
import { computed, inject } from '@angular/core';
import { catchError, finalize, first, firstValueFrom, iif, map, of, pipe, switchMap, tap } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { FeatureStatus } from '../model/feature-status.enum';
import { ErrorHandlerService } from '../../services/error/error-handler.service';
import { SuccessHandlerService } from '../../services/success/success-handler.service';
import { TrackingFactory } from '../../services/tracking/tracking.factory';
import { LoginEvent } from '../../services/tracking/adapters/google-tag-manager/models/recommended-events/login.event';
import { SuedoUser } from '../../interfaces/suedo-user.interface';
import { SuedoAuthResponse } from '../../interfaces/suedo-auth-response.interface';
import { Router } from '@angular/router';

type AuthState = {
  authStatus: FeatureStatus;
  isAuthLoading: boolean;
  user: SuedoUser | null;
  loginError: boolean;
};

const initialAuthState: AuthState = {
  authStatus: FeatureStatus.untouched,
  isAuthLoading: false,
  user: null,
  loginError: false,
};

const authBaseURL = '/api/auth'; // TODO move to config

export function withAuthFeature() {
  return signalStoreFeature(
    withState(initialAuthState),
    withComputed(({ user, authStatus }) => ({
      isLoggedIn: computed(() => authStatus() === FeatureStatus.initialized && user() !== null),
    })),
    withMethods(
      (
        store,
        http = inject(HttpClient),
        errorHandler = inject(ErrorHandlerService),
        successHandler = inject(SuccessHandlerService),
        tracking = inject(TrackingFactory),
        router = inject(Router),
      ) => ({
        login: async (username: string, password: string) => {
          try {
            patchState(store, {
              isAuthLoading: true,
              loginError: false,
            });
            const response = await firstValueFrom(
              http.post<SuedoAuthResponse>(authBaseURL + '/login', {
                username: username,
                password: password,
              }),
            );
            patchState(store, { user: response.oUser });
            successHandler.displaySuccess({
              params: {
                firstName: response.oUser.oContact.sFirstName,
                lastName: response.oUser.oContact.sLastName,
              },
              label: 'success.login',
              translate: true,
              translateScope: 'core',
            });
            await tracking.trackEvent<LoginEvent>({
              name: 'login',
              payload: { method: 'email' },
            });
          } catch (e: any) {
            if (e.status !== 401) {
              errorHandler.handleError({
                label: 'errors.login',
                translate: true,
                translateScope: 'core',
                display: true,
                ...e,
              });
              patchState(store, { loginError: true });
            } else {
              throw e;
            }

          } finally {
            patchState(store, { isAuthLoading: false });
          }
        },
        logout: async () => {
          try {
            patchState(store, { isAuthLoading: true });
            await firstValueFrom(
              http.post<{
                message: string;
                statusCode: number;
              }>(authBaseURL + '/logout', {}),
            );
            patchState(store, { user: null });
            await router.navigate(['/']);
            successHandler.displaySuccess({
              label: 'success.logout',
              translate: true,
              translateScope: 'core',
            });
          } catch (e: any) {
            errorHandler.handleError({
              label: 'errors.logout',
              translate: true,
              translateScope: 'core',
              display: true,
              ...e,
            });
          } finally {
            patchState(store, { isAuthLoading: false });
          }
        },
        changePassword: async (newPassword: string) => {
          try {
            patchState(store, { isAuthLoading: true });
            await firstValueFrom(http.post<AuthResponseInterface>(authBaseURL + '/new-pass', { sNewPassword: newPassword }));
          } catch (e: any) {
            errorHandler.handleError({
              label: 'generic-message',
              translate: true,
              translateScope: 'shared',
              display: true,
              ...e,
            });
          } finally {
            patchState(store, { isAuthLoading: false });
          }
        },
        resetPassword: async (reset: ResetPasswordInterface) => {
          try {
            patchState(store, { isAuthLoading: true });
            await firstValueFrom(http.post<void>(authBaseURL + '/reset-pass', reset));
          } catch (e: any) {
            errorHandler.handleError({
              label: 'generic-message',
              translate: true,
              translateScope: 'shared',
              display: true,
              ...e,
            });
          } finally {
            patchState(store, { isAuthLoading: false });
          }
        },
        requestPassword: async (username: string) => {
          try {
            patchState(store, { isAuthLoading: true });
            await firstValueFrom(http.post<void>(authBaseURL + '/request-pass', { username }));
          } catch (e: any) {
            errorHandler.handleError({
              label: 'generic-message',
              translate: true,
              translateScope: 'shared',
              display: true,
              ...e,
            });
          } finally {
            patchState(store, { isAuthLoading: false });
          }
        },
        reloadAuthUser: async () => {
          patchState(store, { isAuthLoading: true });
          try {
            const authResponse = await firstValueFrom(http.get<AuthResponseInterface<SuedoUser>>(authBaseURL + '/me'));
            patchState(store, { user: authResponse.oUser });
          } catch (e: any) {
            errorHandler.handleError({
              label: 'generic-message',
              translate: true,
              translateScope: 'shared',
              display: true,
              ...e,
            });
          } finally {
            patchState(store, { isAuthLoading: false });
          }
        },
        initAuth: rxMethod<void>(
          pipe(
            tap(() => patchState(store, { isAuthLoading: true })),
            switchMap(() => http.get<HasCookie>(authBaseURL + '/hasCookie')),
            first(),
            switchMap((response) =>
              iif(
                () => response.hasCookie,
                http.get<SuedoAuthResponse>(authBaseURL + '/me').pipe(
                  map((response) => response.oUser),
                ),
                of(null),
              ),
            ),
            catchError((e: HttpErrorResponse) => {
              if (e.status === 401) {
                return http.post<SuedoAuthResponse>(authBaseURL + '/refresh-token', {}).pipe(
                  map((response) => response.oUser),
                );
              }
              return of(null)
            }),
            tap((value) => {
              patchState(store, { user: value });
            }),
            finalize(() =>
              patchState(store, {
                isAuthLoading: false,
                authStatus: FeatureStatus.initialized,
              }),
            ),
          ),
        ),
      }),
    ),
    withHooks({
      onInit({ initAuth }) {
        initAuth();
      },
    }),
  );
}
