import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@jsverse/transloco';
import { first } from 'rxjs';

export interface ErrorInterface extends Error, ConfigurableError {
  /**
   * The error itself
   */
  error?: any;

  /**
   * Track additional parameter
   */
  params?: any;
}

export interface ConfigurableError {
  /**
   * The label for the error
   */
  label: string;

  /**
   * Display error
   * (optional) choose a field to display (defaults to `message`)
   */
  display?: string | boolean;

  /**
   * When the error is displayed, should the message been translated
   */
  translate?: boolean;

  /**
   * When the error is translated, which scope to use
   */
  translateScope?: string;

  /**
   * Ignore error, no advanced processing is done
   */
  ignore?: boolean;
}

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService {
  snackBar = inject(MatSnackBar);
  transloco = inject(TranslocoService);

  public handleError(error: ErrorInterface): void {
    if (error.display) {
      this.displayError(error);
    }

    if (error.ignore) {
      return;
    }

    throw error;
  }

  private displayError(error: ErrorInterface): void {
    if (error.translate) {
      this.transloco
        .selectTranslate(error.label, {}, error.translateScope)
        .pipe(first())
        .subscribe((translation: string) => {
          this.snackBar.open(translation, '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: ['error'],
          });
        });
      return;
    }

    this.snackBar.open(error.label, '', {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: ['error'],
    });
  }
}
