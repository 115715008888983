import { Injectable } from '@angular/core';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { injectQueryParams } from 'ngxtension/inject-query-params';

@Injectable({
  providedIn: 'root',
})
export class FormHelperService {
  queryParams = injectQueryParams();

  public removeError(form: UntypedFormGroup, field: string, errorCode: string) {
    const currentErrors: ValidationErrors = form.get(field)?.errors || {};
    delete currentErrors[errorCode];

    form.get(field)!.setErrors(Object.keys(currentErrors).length ? { ...currentErrors } : null);
  }

  public addError(form: UntypedFormGroup, field: string, errors: ValidationErrors) {
    const currentErrors: ValidationErrors = form.get(field)?.errors || {};
    form.get(field)!.setErrors({ ...currentErrors, ...errors });
  }

  patchFormByQueryParams(form: UntypedFormGroup, emitEvent = false) {
    const queryParams = { ...this.queryParams() };
    this.convertEpochToDateStrings(queryParams);
    form.patchValue(queryParams, { emitEvent});
  }

  convertDatesToEpoch(obj: any): any {
    if (obj === null || typeof obj !== "object") {
      return;
    }

    for (const key in obj) {
      if (key.startsWith("dt") && typeof obj[key] === "string") {
        const date = new Date(obj[key]);
        if (!isNaN(date.getTime())) {
          obj[key] = date.getTime();
        }
      }
    }
  }

  convertEpochToDateStrings(obj: any): any {
    for (const key in obj) {
      if (key.startsWith("dt")) {
        const date = new Date(+obj[key]);
        if (!isNaN(date.getTime())) {
          obj[key] = date.toISOString().slice(0, 10)
        }
      }
    }
  }
}
