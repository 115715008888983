import { Component, inject, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormHelperService } from '../../services/form/form-helper.service';
import { RouterLink } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TextButtonComponent } from '../button/text-button/text-button.component';
import { NgIf } from '@angular/common';
import { InputComponent } from '../form/input/input.component';
import { TranslocoModule } from '@jsverse/transloco';
import { GlobalStore } from '../../store/global.store';
import { UrlPathPipe } from '../../pipes/url-path.pipe';
import { GetErrorPipe } from '../../pipes/get-error.pipe';

export interface LoginModalInterface {
  forgotPassword: boolean;
}

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    ReactiveFormsModule,
    FormsModule,
    InputComponent,
    NgIf,
    TextButtonComponent,
    RouterLink,
    UrlPathPipe,
    GetErrorPipe,
  ],
})
export class LoginModalComponent {
  store = inject(GlobalStore);
  public form: UntypedFormGroup = this.fb.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });

  public requestSend: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private formHelper: FormHelperService,
    @Inject(MatDialogRef) public dialogRef: MatDialogRef<LoginModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LoginModalInterface,
  ) {
  }

  toggleForgotPassword() {
    this.data.forgotPassword = !this.data.forgotPassword;
    this.formHelper.removeError(this.form, 'username', 'server');
    this.formHelper.removeError(this.form, 'password', 'server');
    this.form.controls['password'].reset();
    if (!this.data.forgotPassword) {
      this.requestSend = false;
    }
  }

  public async login(): Promise<void> {
    this.form.markAllAsTouched();
    this.formHelper.removeError(this.form, 'username', 'server');
    this.formHelper.removeError(this.form, 'password', 'server');

    if (!this.form.valid) {
      return;
    }

    const username: string = this.form.value.username;
    const password: string = this.form.value.password;

    try {
      await this.store.login(username, password);
      //await this.router.navigate([this.urlHelper.localizeUrl('profile/my-profile')]);
      this.dialogRef.close('success');
    } catch (exception) {
      this.formHelper.addError(this.form, 'username', { server: true });
      this.formHelper.addError(this.form, 'password', { server: true });
    }
  }

  public async requestPassword(): Promise<void> {
    this.form.markAllAsTouched();
    this.formHelper.removeError(this.form, 'username', 'server');

    if (!this.form.controls['username'].valid) {
      return;
    }

    const username: string = this.form.value.username;

    try {
      await this.store.requestPassword(username);
    } finally {
      this.requestSend = true;
    }
  }
}
