<!-- start modal -->
<ng-container *transloco="let t; scope: 'shared'">
  <div aria-labelledby="modal-title" aria-modal="true" class="js-login-modal fixed z-60 inset-0 overflow-y-auto"
       role="dialog">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div aria-hidden="true" class="fixed inset-0 bg-gray-200 bg-opacity-75 transition-opacity"></div>
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span aria-hidden="true" class="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
      <form
        class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
      >
        <div class="block absolute top-0 right-0 pt-4 pr-4 z-10">
          <button
            (click)="cancel()"
            class="js-close-modal bg-white p-2 inline-flex items-center justify-center hover:text-primary hover:bg-gray-100 focus:text-primary rounded"
            type="button"
          >
            <svg
              aria-hidden="true"
              class="h-5 w-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M6 18L18 6M6 6l12 12" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
            </svg>
          </button>
        </div>
        <div class="flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:text-left w-full">
            <h1 class="text-lg leading-6 font-bold" id="modal-title">
              {{ t("shared.toxin-modal.header") }}
            </h1>
            <div class="mt-2">
              <h3 class="text-lg hover:text-primary">
                {{ oCartItem.oArticle.sName }}
              </h3>
              <p class="text-sm mt-2">
                {{ t("shared.toxin-modal.msg", { sArticleID: oCartItem.sArticleID }) }}
              </p>
            </div>
            <div class="mt-5 sm:mt-4 flex flex-row justify-between space-x-4">
              <button
                (click)="cancel()"
                class="w-auto mt-2 inline-flex items-center px-1.5 md:px-3.5 py-1.5 border border-transparent text-xs font-bold rounded shadow-sm text-primary bg-primary text-white hover:bg-secondary group focus:bg-secondary"
                type="button"
              >
                <span class="text-sm xl:text-base font-bold">
                  {{ t("shared.toxin-modal.ok") }}
                </span>
              </button>
              <button
                (click)="openLoginModal()"
                *ngIf="!store.isLoggedIn()"
                class="w-auto mt-2 inline-flex items-center px-1.5 md:px-3.5 py-1.5 border border-transparent text-xs font-bold rounded shadow-sm text-primary bg-primary text-white hover:bg-secondary group focus:bg-secondary"
                type="button"
              >
                <span class="text-sm xl:text-base font-bold">
                  {{ t("shared.toxin-modal.login") }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>
<!-- end modal -->
