import { signalStore } from '@ngrx/signals';
import { withAuthFeature } from './feature/auth.feature';
import { withFavoriteFeature } from './feature/favorite.feature';
import { withCartFeature } from './feature/cart.feature';
import { withLanguageFeature } from './feature/language.feature';
import { withCompareFeature } from './feature/compare.feature';

export const GlobalStore = signalStore(
  { providedIn: 'root' },
  withAuthFeature(),
  withCartFeature(),
  withFavoriteFeature(),
  withLanguageFeature(),
  withCompareFeature(),
);
