import { AuthResponseInterface, FavoriteItem, FavoriteList, PaginationResponseInterface, User } from '@lobos/common-v3';
import { patchState, signalStoreFeature, type, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { computed, effect, inject, Signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, map } from 'rxjs';
import { ErrorHandlerService } from '../../services/error/error-handler.service';
import {
  CreateFavoriteItemDto,
  CreateFavoriteListDto,
  FavoriteMasterEntity,
  UpdateFavoriteListDto,
} from '@lobos/gateway-v3';
import { FeatureStatus } from '../model/feature-status.enum';
import { TrackingFactory } from '../../services/tracking/tracking.factory';
import { SuedoFavoriteItem } from '../../interfaces/suedo-favorite-item.interface';
import { LbsFavoriteItem } from '../model/lbs-favorite-item.interface';

export type FullFavoriteList = {
  listHeader: FavoriteList;
  listItems: SuedoFavoriteItem[];
};

export type FavoriteState = {
  activeFavoriteListId: string | null;
  allFavoriteItemLists: FullFavoriteList[];
  isFavoriteLoading: boolean;
};

const initialFavoriteState: FavoriteState = {
  activeFavoriteListId: null,
  allFavoriteItemLists: [],
  isFavoriteLoading: false,
};

const favoriteBaseURL = '/api/favorites'; // TODO move to config
const userBaseURL = '/api/users'; // TODO move to config
const authBaseURL = '/api/auth'; // TODO move to config

export function withFavoriteFeature() {
  return signalStoreFeature(
    {
      state: type<{
        user: User | null;
        authStatus: FeatureStatus;
      }>(),
      signals: type<{
        isLoggedIn: Signal<boolean>;
      }>(),
    },
    withState(initialFavoriteState),
    withComputed(({
                    activeFavoriteListId,
                    allFavoriteItemLists,
                  }) => ({
      activeFavoriteList: computed(() => {
        if (activeFavoriteListId() && allFavoriteItemLists().length) {
          return allFavoriteItemLists().find((list) => list.listHeader.gListID === activeFavoriteListId()) as FullFavoriteList;
        }
        return undefined;
      }),
    })),
    withMethods(
      (
        store,
        http = inject(HttpClient),
        errorHandler = inject(ErrorHandlerService),
        tracking = inject(TrackingFactory),
      ) => ({
        changeActiveFavoriteList: async (newActiveListId: string) => {
          patchState(store, { isFavoriteLoading: true });
          await changeActiveListFn(newActiveListId, store, http, errorHandler);
          patchState(store, { isFavoriteLoading: false });
        },
        deleteFavoriteItem: async (listId: string, itemId: string) => {
          try {
            patchState(store, { isFavoriteLoading: true });

            await firstValueFrom(http.delete(`${favoriteBaseURL}/${listId}/items/${itemId}`));

            const newAllLists = [...store.allFavoriteItemLists()].map((list: FullFavoriteList) =>
              list.listHeader.gListID === listId
                ? {
                  ...list,
                  listItems: [...list.listItems.filter((item) => item.shtItemID != itemId)],
                }
                : list,
            );
            patchState(store, { allFavoriteItemLists: newAllLists });
            await tracking.trackEvent({
              name: 'remove_wishlist_item',
              payload: {},
            });
            // successHandler.displaySuccess({
            //   label: 'success.remove-favorite-item',
            //   translate: true,
            //   translateScope: 'favorite',
            // });
          } catch (e: any) {
            errorHandler.handleError({
              label: 'errors.delete-favorite-item',
              translate: true,
              translateScope: 'favorite',
              display: true,
              ...e,
            });
          } finally {
            patchState(store, { isFavoriteLoading: false });
          }
        },
        updateFavoriteItem: async (listId: string, itemId: string | number, favoriteItemDto: LbsFavoriteItem): Promise<void> => {
          try {
            patchState(store, { isFavoriteLoading: true });

            await firstValueFrom(http.put(`${favoriteBaseURL}/${listId}/items/${itemId}`, favoriteItemDto));

            const newAllLists = [...store.allFavoriteItemLists()].map((list: FullFavoriteList) =>
              list.listHeader.gListID === listId
                ? {
                  ...list,
                  listItems: list.listItems.map((item) => (item.shtItemID == itemId ? { ...favoriteItemDto } : item)),
                }
                : list,
            );
            patchState(store, { allFavoriteItemLists: newAllLists });
          } catch (err: any) {
            errorHandler.handleError({
              label: 'errors.update-favorite-item',
              translate: true,
              translateScope: 'favorite',
              display: true,
              ...err,
            });
          } finally {
            patchState(store, { isFavoriteLoading: false });
          }
        },
        createFavoriteItem: async (favoriteItemDto: CreateFavoriteItemDto, listId: string | null) => {
          try {
            patchState(store, { isFavoriteLoading: true });
            if (!listId) {
              await createFavoriteListFn(
                {
                  sListname: new Intl.DateTimeFormat('de-CH', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  }).format(new Date()),
                },
                http,
                store,
                errorHandler,
              );
              favoriteItemDto.gListID = store.activeFavoriteListId()!;
              listId = store.activeFavoriteListId();
            }

            const newItem = await firstValueFrom(http.post<FavoriteItem>(`${favoriteBaseURL}/${listId}/items`, favoriteItemDto));

            const newAllLists = [...store.allFavoriteItemLists()].map((list: FullFavoriteList) => {
              if (list.listHeader.gListID === listId) {
                const newListItems = [...list.listItems];
                newListItems.push(newItem);
                return {
                  ...list,
                  listItems: newListItems,
                };
              }
              return list;
            });
            patchState(store, { allFavoriteItemLists: newAllLists });
            // successHandler.displaySuccess({
            //   params: { articleName: newItem?.oArticle?.sName },
            //   label: 'success.add-favorite-item',
            //   translate: true,
            //   translateScope: 'favorite',
            // });
            await tracking.trackEvent({
              name: 'add_wishlist_item',
              payload: {},
            });
          } catch (err: any) {
            errorHandler.handleError({
              label: 'errors.create-favorite-item',
              translate: true,
              translateScope: 'favorite',
              display: true,
              ...err,
            });
          } finally {
            patchState(store, { isFavoriteLoading: false });
          }
        },
        deleteFavoriteList: async (listId: string) => {
          try {
            patchState(store, { isFavoriteLoading: true });

            await firstValueFrom(http.delete(`${favoriteBaseURL}/${listId}`));

            const newAllLists = [...store.allFavoriteItemLists().filter((list) => list.listHeader.gListID !== listId)];
            patchState(store, { allFavoriteItemLists: newAllLists });
            if (listId === store.activeFavoriteListId()) {
              newAllLists[0].listHeader.gListID
                ? await changeActiveListFn(newAllLists[0].listHeader.gListID.toString(), store, http, errorHandler)
                : await createFavoriteListFn(
                  {
                    sListname: new Intl.DateTimeFormat('de-CH', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    }).format(new Date()),
                  },
                  http,
                  store,
                  errorHandler,
                );
            }
          } catch (err: any) {
            errorHandler.handleError({
              label: 'errors.delete-favorite-list',
              translate: true,
              translateScope: 'favorite',
              display: true,
              ...err,
            });
          } finally {
            patchState(store, { isFavoriteLoading: false });
          }
        },
        updateFavoriteList: async (listId: string, updateListDto: UpdateFavoriteListDto) => {
          try {
            patchState(store, { isFavoriteLoading: true });

            const response = await firstValueFrom(http.put<FavoriteList>(`${favoriteBaseURL}/${listId}`, updateListDto));

            const newAllLists = [...store.allFavoriteItemLists()].map((list: FullFavoriteList) =>
              list.listHeader.gListID === listId
                ? {
                  ...list,
                  listHeader: {
                    ...list.listHeader,
                    sListname: response.sListname,
                  },
                }
                : list,
            );
            patchState(store, { allFavoriteItemLists: newAllLists });
          } catch (err: any) {
            errorHandler.handleError({
              label: 'errors.update-favorite-list',
              translate: true,
              translateScope: 'favorite',
              display: true,
              ...err,
            });
          } finally {
            patchState(store, { isFavoriteLoading: false });
          }
        },
        createFavoriteList: async (createFavListDto: CreateFavoriteListDto) => {
          patchState(store, { isFavoriteLoading: true });
          await createFavoriteListFn(createFavListDto, http, store, errorHandler);
          patchState(store, { isFavoriteLoading: false });
          await tracking.trackEvent({
            name: 'add_wishlist_list',
            payload: {},
          });
        },
        initFavorite: async (isLoggedIn: boolean, authStatus: FeatureStatus) => {
          try {
            if (authStatus === FeatureStatus.initialized) {
              if (isLoggedIn) {
                patchState(store, { isFavoriteLoading: true });
                // can't use store.user().gActiveBookmarkListID because user() is a signal and when we use signals in a network request it gets triggered every time the signal changes
                const activeListId = await firstValueFrom(
                  http.get<AuthResponseInterface>(authBaseURL + '/me').pipe(map((user) => user.oUser.gActiveBookmarkListID?.toString())),
                );
                if (activeListId) {
                  const paginatedHeads = await firstValueFrom(http.get<PaginationResponseInterface<FavoriteMasterEntity>>(favoriteBaseURL));

                  const updatedLists = [];
                  for (const head of paginatedHeads.data) {
                    const paginatedItems = await firstValueFrom(
                      http.get<PaginationResponseInterface<LbsFavoriteItem>>(`${favoriteBaseURL}/${head.gListID}/items`),
                    );
                    const listItems = paginatedItems.data.length ? paginatedItems.data : [];
                    updatedLists.push({
                      listHeader: head,
                      listItems,
                    });
                  }
                  patchState(store, {
                    allFavoriteItemLists: updatedLists,
                    activeFavoriteListId: activeListId,
                  });
                }
              } else {
                patchState(store, {
                  allFavoriteItemLists: [],
                  activeFavoriteListId: null,
                });
              }
            }
          } catch (err: any) {
            errorHandler.handleError({
              label: 'Error while initializing favorite state feature.',
              display: false,
              ...err,
            });
          } finally {
            patchState(store, { isFavoriteLoading: false });
          }
        },
      }),
    ),
    withHooks({
      onInit({
               authStatus,
               isLoggedIn,
               initFavorite,
             }) {
        effect(
          () => {
            initFavorite(isLoggedIn(), authStatus());
          },
          { allowSignalWrites: true },
        );
      },
    }),
  );
}

async function createFavoriteListFn(
  createFavListDto: CreateFavoriteListDto,
  http: HttpClient,
  store: any,
  errorHandler: ErrorHandlerService,
) {
  try {
    const response = await firstValueFrom(http.post<FavoriteList>(favoriteBaseURL, createFavListDto));

    const updatedLists = [...store.allFavoriteItemLists()];
    updatedLists.push({
      listHeader: response,
      listItems: [],
    });
    patchState(store, { allFavoriteItemLists: updatedLists });
    await changeActiveListFn(response.gListID.toString(), store, http, errorHandler);
  } catch (err: any) {
    errorHandler.handleError({
      label: 'errors.create-favorite-list',
      translate: true,
      translateScope: 'favorite',
      display: true,
      ...err,
    });
    patchState(store, { isFavoriteLoading: false });
  }
}

async function changeActiveListFn(newActiveListId: string, store: any, http: HttpClient, errorHandler: ErrorHandlerService) {
  try {
    if (store.isLoggedIn()) {
      // update user
      const updatedUser = store.user()!;
      updatedUser.gActiveBookmarkListID = newActiveListId;
      const user = await firstValueFrom(http.put<User>(`${userBaseURL}/${store.user()!.lngContactID}`, updatedUser));
      patchState(store, { user: user });

      patchState(store, { activeFavoriteListId: newActiveListId });
    }
  } catch (e: any) {
    errorHandler.handleError({
      label: 'errors.change-active-list',
      translate: true,
      translateScope: 'favorite',
      display: true,
      ...e,
    });
    patchState(store, { isFavoriteLoading: false });
  }
}
