import { patchState, signalStoreFeature, withMethods, withState } from '@ngrx/signals';
import { AlternateVersionInterface } from '../../services/alternate-version/alternate-version.interface';

type LanguageState = {
  alternateLinks: AlternateVersionInterface[];
};

const initialState: LanguageState = {
  alternateLinks: [],
};

export function withLanguageFeature() {
  return signalStoreFeature(
    {},
    withState(initialState),
    withMethods((store) => ({
      setAlternateLink: (alternateLinks: AlternateVersionInterface[]) => patchState(store, { alternateLinks: alternateLinks }),
    })),
  );
}
