import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgIf, NgClass } from '@angular/common';

@Component({
  selector: 'app-text-button',
  templateUrl: './text-button.component.html',
  styleUrls: ['./text-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgClass],
})
export class TextButtonComponent {
  @Input() public text: string = '';
  @Input() public type: string = 'button';
  @Input() public classes: string = '';
  @Input() public primary: boolean = false;
  @Input() public isLoading: boolean = false;
  @Input() public disabled: boolean = false;
}
